<template>
  <div>
    <div class="row">
      <!--Code for ToggleDrawer-->
      <div
        class="col-2 col-md-1 content"
        slot="content"
        style="height: 70px;z-index: 999999;background-color: rgb(255, 255, 255) !important;margin-left: 10px;"
      >
        <a href="javascript:void(0)" @click="showDrawer">
          <i
            class="fa fa-2x fa-bars icon menuicon-participant-style"
            aria-hidden="true"
            v-show="showmenuicon"
          ></i>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 2540 3175"
            style="enable-background:new 0 0 2540 3175;  margin-top:30px;"
            xml:space="preserve"
            height="30"
            width="30"
            v-show="!showmenuicon"
          >
            <g>
              <path
                d="M1878,2270c39,39,39,104,0,143c-40,40-104,40-144,0L662,1342c-39-40-39-104,0-144L1734,127c40-40,104-40,144,0
		c39,39,39,104,0,143L878,1270L1878,2270z"
              />
            </g>
          </svg>
        </a>
      </div>
      <div class="col-6 col-md-8 text-left" style="padding-left: 0px !important;">
        <label class="participant-merchant-style">
          <b>Participating Merchants</b>
        </label>
      </div>
      <!--Code for CanPay Logo-->
      <div class="col-3 col-md-2 text-right" style="padding-left: 0px !important">
        <a
          data-toggle="collapse"
          href="#collapseExample"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
          v-on:click="clickONfav"
        >
          <span style="float: right !important; margin-top: 30px">
            <svg
              version="1.1"
              id="Layer"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 125"
              style="enable-background:new 0 0 100 125;"
              xml:space="preserve"
              height="30"
              width="30"
              :fill="favcolor"
            >
              <path
                d="M90.4,84L75.8,69.5C87.2,54.9,86,34.1,72.9,21C66,14.1,56.8,10.3,47,10.3S28,14.1,21.1,21S10.4,37.1,10.4,46.9
	s3.8,19,10.7,25.9S37.2,83.5,47,83.5c8.1,0,16.1-2.7,22.5-7.8L84,90.4c0.9,0.9,2,1.3,3.2,1.3s2.3-0.5,3.2-1.3
	C92.2,88.7,92.2,85.8,90.4,84z M74.6,46.9c0,7.4-2.9,14.3-8.1,19.5S54.3,74.5,47,74.5s-14.3-2.9-19.5-8.1s-8.1-12.2-8.1-19.5
	c0-7.4,2.9-14.3,8.1-19.5s12.2-8.1,19.5-8.1c7.4,0,14.3,2.9,19.5,8.1S74.6,39.5,74.6,46.9z"
              />
            </svg>
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import DrawerLayout from "vue-drawer-layout";
export default {
  name: "ParticipantHeader",
  data: () => ({
    open: true,
    searchOpen: false,
    filterOpen: false,
    showmenuicon: true,
    favcolor: "#000000",
    favcolor2: "#000000"
  }),

  components: {
    DrawerLayout
  },
  mounted() {
    this.$root.$emit("loginapp", [""]);

    let self = this;
    self.$root.$on("Menu Drawer Close", function(data) {
      console.log("Drawerclose");
      this.showmenuicon = true;
    });

    self.$root.$on("Menu Drawer Open", function(data) {
      setTimeout(function() {
        self.showmenuicon = false;
      }, 30);
    });
  },
  methods: {
    clickONfav(data) {
      if (this.searchOpen == false) {
        this.searchOpen = true;
        this.favcolor = "#1b9142";
      } else {
        this.searchOpen = false;
        this.favcolor = "#000000";
      }
    },
    clickOnFilter() {
      if (this.filterOpen == false) {
        this.filterOpen = true;
        this.favcolor2 = "#1b9142";
      } else {
        this.filterOpen = false;
      }
      this.$root.$emit("fliterOn", [""]);
    },
    showDrawer() {
      this.showmenuicon = false;
      this.$root.$emit("Menu Drawer", [""]);
    }
  }
};
</script>
<style scoped>
#container {
  height: 100px !important;
}

#row-header {
  height: 100px !important;
}

#col-header {
  margin-top: 23px;
  margin-left: -20px;
}
</style>
