<template>
  <div class="pre-header">
    <div class="row pre-header-height">
      <div class="col-2 align-self-center">
        <a href="javascript:void(0)" @click="clickBack()">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 125"
            style="enable-background: new 0 0 100 125"
            xml:space="preserve"
            height="40"
            width="30"
            fill="#000000"
          >
            <g id="Layer_2"></g>
            <g id="Layer_1">
              <path
                d="M12.1,47.1C12.1,47.1,12.1,47.1,12.1,47.1l29.5-29.5c1.6-1.6,4.2-1.6,5.8,0c1.6,1.6,1.6,4.2,0,5.8L24.9,45.9h61.8
		c2.3,0,4.1,1.8,4.1,4.1s-1.8,4.1-4.1,4.1H24.9l22.5,22.5c1.6,1.6,1.6,4.2,0,5.8c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2
		L12.1,52.9c0,0,0,0,0,0L9.2,50L12.1,47.1z"
              />
            </g>
          </svg>
        </a>
      </div>
      <div class="col-8 align-self-center">
        <img
          class="pre-header-logo"
          src="../../../assets/images/canpay-logo.png"
        />
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LocateRetailerHeader",
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  methods: {
    clickBack() {
      if(this.$route.path === '/locateretailer')
        this.$root.$emit("backToMerchantMiniDetailLocator", [""]);
      else this.$router.go(-1);
    },
  },
};
</script>
